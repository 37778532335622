<template>
  <div class="content" style="display: flex; justify-content: center">
    <div
      v-if="loading === false && message.text === null"
      class="form-stripe iphone"
    >
      <div id="ticket-component">
        <img
          style="height: 100px; width: 100%; object-fit: cover"
          src="https://firebasestorage.googleapis.com/v0/b/md-music-rdc.appspot.com/o/Covers%2Fimage_picker_6A3515A5-52F3-440C-BF3C-5F88E9CD7F21-20325-00000F908A495D3A.jpg?alt=media&token=36bdc1f5-7053-4c5f-97a1-0c4ba1b39031"
          alt=""
          srcset=""
        />
        <div style="text-align: center">
          <img
            height="100"
            width="100"
            src="/img/app_logo.png"
            alt=""
            srcset=""
          />
        </div>
        <br />
        <!-- <h1 style="text-align: center; color: #fff">Mon ticket</h1> -->
        <ul class="event-list-infos">
          <li class="flex space-x-auto bottom-1 baseline">
            <div>
              <p class="white event-type-pricing">Id transactions</p>
            </div>
            <div>
              <p class="pink">
                {{ invoice?.transaction?.transId }}
              </p>
            </div>
          </li>
          <li class="flex space-x-auto bottom-1 baseline">
            <div>
              <p class="white event-type-pricing">Evenement</p>
            </div>
            <div>
              <p class="pink">
                {{ invoice?.article?.name }}
              </p>
            </div>
          </li>
          <li class="flex space-x-auto bottom-1 baseline">
            <div>
              <p class="white event-type-pricing">Lieu</p>
            </div>
            <div>
              <p class="pink">
                {{ invoice?.article?.place }}
              </p>
            </div>
          </li>
          <li class="flex space-x-auto bottom-1 baseline">
            <div>
              <p class="white event-type-pricing">Date et heure</p>
            </div>
            <div>
              <p class="pink">
                {{
                  invoice &&
                  invoice.article &&
                  convertNormalDateWithMinute(invoice.article.startDate)
                }}
              </p>
            </div>
          </li>
          <!-- <li class="flex space-x-auto bottom-1 baseline">
            <div>
              <p class="white event-type-pricing">Type de billet</p>
            </div>
            <div>
              <p class="pink">
                {{ invoice && invoice.type }}
              </p>
            </div>
          </li> -->
          <li class="flex space-x-auto bottom-1 baseline">
            <div>
              <p class="white event-type-pricing">Prix</p>
            </div>
            <div>
              <p class="pink">{{ invoice && invoice.transaction.amount }}$</p>
            </div>
          </li>
        </ul>

        <div style="text-align: center">
          <qrcode-vue
            background="#8E9EF5"
            class="code-qr-element"
            :value="value"
            :size="size"
            level="H"
          />
        </div>
      </div>

      <br />
      <small
        >Veuillez telecharger votre votre article et votre preuve de paiement en
        cliquant sur le bouton ci-dessous.</small
      >
      <br /><br />

      <button @click="downloadQrcode" class="button button--full" type="submit">
        Télécharger le ticket
      </button>
    </div>
    <template v-if="loading">
      <div style="display: block">
        <div style="display: flex; justify-content: center">
          <loader />
        </div>
        <div v-if="loadingRefresh === false" style="text-align: center">
          <h4>
            Si après paiement le code QR ne s'affiche pas, veuillez cliquer sur
            le bouton ci-dessous
          </h4>
          <br />
          <br />
          <button
            @click="refreshDatas"
            style="
              background: #ddd;
              color: #333;
              padding: 0.5em 2em;
              border-radius: 4px;
            "
          >
            Cliquer ici
          </button>
        </div>
      </div>
    </template>
    <template v-if="message.text">
      <div style="display: block">
        <div
          style="
            display: flex;
            margin: auto;
            justify-content: center;
            margin-top: 2em;
          "
        >
          <icon-empty />
        </div>
        <div style="text-align: center; margin-top: 2em">
          {{ message.text }}
        </div>
        <br />
        <div style="text-align: center">
          <button
            @click="refresh"
            style="
              background: #ddd;
              color: #333;
              padding: 0.5em 2em;
              border-radius: 4px;
            "
          >
            Recharger la page
          </button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import domtoimage from "dom-to-image";
import QrcodeVue from "qrcode.vue";
import Loader from "../components/Loader";
import IconEmpty from "../components/IconEmpty";
import { getRequest } from "../configs/functions";
import { convertNormalDateWithMinute } from "../configs/functions";

import io from "socket.io-client";

export default {
  components: { QrcodeVue, Loader, IconEmpty },
  data() {
    return {
      invoice: "",
      loading: false,
      loadingRefresh: false,
      object: "",
      value: "https://www.mdmusicrdc.com/event/" + this.$route.params.transId,
      size: 150,
      message: {
        type: null,
        text: null,
      },
    };
  },
  methods: {
    downloadQrcode() {
      var ticket = document.getElementById("ticket-component");
      domtoimage
        .toPng(ticket)
        .then(function (dataUrl) {
          var link = document.createElement("a");
          link.download = "ticket.jpg";
          link.href = dataUrl;
          link.click();
        })
        .catch(function (error) {
          console.error("oops, something went wrong!", error);
        });
    },
    refresh() {
      window.location.reload();
    },
    socketVodacom(transId) {
      this.loading = true;
      // const ref = this.$route.params.object
      // const title = this.$route.params.title
      // const id = this.$route.params.id

      const socket = io("https://api-mdmusicrdc.herokuapp.com");

      socket.emit("join", transId);

      socket.on("callback", (data) => {
        console.log(data);
        if (data.state) {
          getRequest(
            `api/transaction/details/${transId}`,
            (state, response) => {
              this.loading = false;
              if (state) {
                console.log(response);
              } else {
                this.message = {
                  text: response.message,
                  type: "error",
                };
              }
            }
          );
        } else {
          this.loading = false;
          this.message = {
            text: data.message,
            type: "error",
          };
        }
      });
    },
    refreshDatas() {
      var transId = this.$route.params.transId;
      this.loading = true;
      this.loadingRefresh = true;

      getRequest(`api/transaction/details/${transId}`, (state, response) => {
        this.loading = false;
        this.loadingRefresh = false;

        if (state) {
          this.invoice = response;
        } else {
          this.message = {
            text: response,
            type: "error",
          };
        }
      });
    },
    convertNormalDateWithMinute,
  },
  mounted() {
    this.object = this.$route.params.object;

    var ref = this.$route.params.payementType;
    var transId = this.$route.params.transId;

    if (ref === "stripe") {
      getRequest(`api/transaction/details/${transId}`, (state, response) => {
        if (state) {
          this.invoice = response;
          console.log(response);
        }
      });
    } else {
      this.socketVodacom(transId);
    }
  },
};
</script>

<style>
</style>