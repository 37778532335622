<template>
  <div class="content">
    <div v-if="object.state" class="iphone">
      
      <div style="text-align: center">
        <img style="object-fit: cover;border-radius: 5px" width="120" height="120" :src="object.datas.event.cover" />
      </div>
      <form style="display:initial" class="form" method="POST">

        <fieldset>
          
          <legend style="text-align: center" class="info">
            {{object.datas.event.name}}
          </legend>

          <header class="header">
            <h1 style="font-weight: 700;">Ticket {{object.datas.ticket.type}}</h1>
          </header>

            <legend style="text-align:center" class="input-label">Espace réservé aux organisateurs</legend>
            <div>
                <input v-model="code" placeholder="Insérer le code de désactivation" class="form-control" name="number-phone" type="text" />
            </div>

        </fieldset>

        <div>
          <button :disabled="loading" style="border-radius:999em;margin-top:1em" @click="pay" class="button button--full">
            {{loading ? 'Verification en cours' : 'Continuer'}}
          </button>
        </div>

      </form>
    </div>

    <template v-if="object.isLoading">
        <div style="display: flex;justify-content: center;align-items: center;height: 18em;" class="content container-fluid">
            <loader />
        </div>
    </template>

    <template v-if="object.state === false">
        <div style="display: flex; margin: auto; justify-content: center;margin-top: 2em" >
            <icon-empty />
        </div>
        <div style="text-align:center;margin-top: 2em">{{ object.message }}</div> <br />
        <div style="text-align: center">
          <button @click="refresh" style="background: #ddd; color: #333; padding: .5em 2em;border-radius: 4px">Rafraichir</button>
        </div>
    </template>
  </div>
</template> 

<script>
import { getRequest, putRequest } from "../configs/functions"
import Loader from "../components/Loader"
import IconEmpty from "../components/IconEmpty"

export default {
  name: 'Event',
  components: { Loader, IconEmpty },
  data(){
    return {
      paymentMethod: "visa",
      code: '',
      loading: false,
      object: {
        isLoading: true
      }
    }
  },
  methods: {
    getObject(){
      try {
        let id = this.$route.params.id

        this.object = {
          isLoading: true
        }

        getRequest(`api/event/ticket/${id}`, (state, response) => {
          console.log(response)
          if (state) {
            this.object = {
              isLoading: false,
              datas: response,
              state: true
            }
          } else {
            this.object = {
              isLoading: false,
              datas: null,
              state: false,
              message: response
            }
          }
        })
      } catch (error) {
        this.object = {
          isLoading: false,
          datas: null,
          state: false,
          message: error
        }
      }
    },
    pay(e){
      e.preventDefault()
      try {
        if (this.code) {

          this.loading = true

          var id = this.$route.params.id
          
          var payload = {
            "code": this.code,
          }

          putRequest(`api/event/ticket/${id}`, payload, (state, response) => {
            this.loading = false
            if (state) {
                this.$swal({
                    title: 'Confirmation evenement',
                    text: response.message,
                    icon: 'success',
                });
            } else {
                this.$swal({
                    title: 'Confirmation evenement',
                    text: response,
                    icon: 'warning',
                });
            }
          })

        } else {
          this.$swal({
            title: 'Paiement',
            text: "Veuillez renseigner le code de confirmation",
            icon: 'warning',
          });
        }
      } catch (error) {
        this.$swal({
          title: 'Confirmation evenement',
          text: error,
          icon: 'error',
        });
      }
    }
  },
  mounted(){
    this.getObject()
  }
}
</script>

<style>




</style>
