<template>
    <div class="event-content">
        <template v-if="object.state">
            <img class="event-content-img" :src="object.datas.cover" alt="" srcset="">
            <div style="padding: 2.5em 1.5em 1em;">
                <h1 class="event-content-title">{{object.datas.name}}</h1>
                <p class="event-content-localisation">{{object.datas.description}}</p>
                <ul class="event-list-infos">
                    <li class="flex">
                        <span style="margin-top:.3em" class="white">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                        </span>
                        <div class="event-list-infos-left">
                            <p class="white bold">
                                {{convertNormalDate(object.datas.startDate)}}
                            </p>
                            <p>
                                {{object.datas.startTime}} - {{object.datas.endTime}}
                            </p>
                        </div>
                    </li>
                    <li class="flex">
                        <span style="margin-top:.3em" class="white">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg>
                        </span>
                        <div class="event-list-infos-left">
                            <p class="white bold">
                                {{object.datas.place}}
                            </p>
                            <p>
                                {{object.datas.address}}
                            </p>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="line"></div>
            <div style="padding: 2em 1.5em 1em;">
                <ul class="event-list-infos">
                    <li v-if="object.datas.priceStandard" class="flex space-x-auto bottom-1 baseline">
                        <div>
                            <p class="white event-type-pricing">
                                Billet Standard
                            </p>
                        </div>
                        <div>
                            <p class="pink">
                                Prix : {{object.datas.priceStandard}}$
                            </p>
                        </div>
                        <button @click="butTicket('standard')" class="button-shop">
                            Acheter
                        </button>
                    </li>
                    <li v-if="object.datas.priceCouple" class="flex space-x-auto bottom-1 baseline">
                        <div>
                            <p class="white event-type-pricing">
                                Billet Couple
                            </p>
                        </div>
                        <div>
                            <p class="pink">
                                Prix : {{object.datas.priceCouple}}$
                            </p>
                        </div>
                        <button @click="butTicket('couple')" class="button-shop">
                            Acheter
                        </button>
                    </li>
                    <li v-if="object.datas.priceVIP" class="flex space-x-auto bottom-1 baseline">
                        <div>
                            <p class="white event-type-pricing">
                                Billet VIP
                            </p>
                        </div>
                        <div>
                            <p class="pink">
                                Prix : {{object.datas.priceVIP}}$
                            </p>
                        </div>
                        <button @click="butTicket('vip')" class="button-shop">
                            Acheter
                        </button>
                    </li>
                </ul>
            </div>
        </template>

        <template v-if="object.isLoading">
            <div style="display: flex;justify-content: center;align-items: center;height: 18em;" class="content container-fluid">
                <loader />
            </div>
        </template>

        <template v-if="object.state === false">
            <div style="display: flex; margin: auto; justify-content: center;padding-top: 2em" >
                <icon-empty />
            </div>
            <div style="text-align:center;margin-top: 2em">{{ object.message }}</div> <br />
            <div style="text-align: center">
            <button @click="refresh" style="background: #ddd; color: #333; padding: .5em 2em;border-radius: 4px">Rafraichir</button>
            </div>
        </template>

    </div>
</template>

<script>

import { getRequest, convertNormalDate, convertToSlug } from "../configs/functions"
import Loader from "../components/Loader"
import IconEmpty from "../components/IconEmpty"


export default {
    name: 'EventDetails',
    components: { Loader, IconEmpty },
    data () {
        return {
            object: {
                isLoading: true
            }
        }
    },
    methods: {
        async butTicket(object) {
            await localStorage.setItem('ticket', object)
            if (this.object.state) {

                let {id, name} = this.object.datas;

                window.location.href = '/events/' +  convertToSlug(name) + '/' + id
            }
        },
        getObject(){
            try {
                let id = this.$route.params.id

                this.object = {
                    isLoading: true
                }

                getRequest(`api/event/${id}`, (state, response) => {
                    if (state) {
                        this.object = {
                            isLoading: false,
                            datas: response,
                            state: true
                        }
                    } else {
                        this.object = {
                            isLoading: false,
                            datas: null,
                            state: false,
                            message: response
                        }
                    }
                })

            } catch (error) {
                this.object = {
                    isLoading: false,
                    datas: null,
                    state: false,
                    message: error
                }
            }
        },convertNormalDate
    },
    mounted() {
        this.getObject()
    }
}
</script>

<style>
    .event-content{
        background: #1a1d22;
        min-block-size: 100vh;
    }

    .event-content-img{
        width: 100%;
        height: 16em;
        object-fit: cover;
    }

    .event-content-title{
        color: #fff;
        font-weight: 600;
        font-size: 26px;
        margin: 0px;
    }

    .event-content-localisation {
        font-size: 16px;
        padding-top: 1.5em;
        padding-bottom: 1em;
        margin: 0;
    }

    .event-list-infos{
        list-style: none;
        padding: 0;
    }

    .event-list-infos-left {
        margin-left: 2.5em;
    }

    .event-type-pricing{
        min-width: 106px;
    }

    .flex{
        display: flex;
    }

    .space-x-auto {
        justify-content: space-between;
    }

    .white {
        color: #fff;
    }

    .bold {
        font-weight: 600;
    }

    .line {
        border-bottom: 1px solid #2a2f45;
    }

    .pink {
        color: #8E9EF5;
    }

    .bottom-1{
        margin-bottom: 1em;
    }

    .button-shop {
        padding: .5em;
        border-radius: 20px;
        padding: 0.5em 1.5em;
        border: none;
        background: #7D51FE;
        color: #fff;
    }

    .baseline {
        align-items: baseline;
    }

</style>

