<template>
    <div class="content" style="display: flex;justify-content: center;">
        <div v-if="object.state" class="form-stripe iphone">

            <div style="text-align: center;">
                <img style="object-fit: cover;border-radius: 5px" width="120" height="120" :src="object.datas.album.cover" />
            </div> <br>

            <h1 style="text-align: center; color: #fff">Voici le contenu de l'album {{object.datas.album.name}}</h1>
            
            <ul class="event-list-infos">
                <li style="border-bottom: 1px solid #4c4848;padding-bottom: 1.5em;" :key="song" v-for="song in object.datas.songs" class="flex space-x-auto bottom-1 baseline">
                    <div>
                        <p class="white event-type-pricing">
                            {{song.name}}
                        </p>
                    </div>
                    <button @click="download(song)" class="button-shop">
                        <i class="pe-7s-cloud-download"></i> 
                    </button>
                </li>
            </ul>
        </div>

        <template v-if="object.isLoading">
            <div style="display: flex;justify-content: center;align-items: center;height: 18em;" class="content container-fluid">
                <loader />
            </div>
        </template>

        <template v-if="object.state === false">
            <div style="display: flex; margin: auto; justify-content: center;margin-top: 2em" >
                <icon-empty />
            </div>
            <div style="text-align:center;margin-top: 2em">{{ object.message }}</div> <br />
            <div style="text-align: center">
            <button @click="refresh" style="background: #ddd; color: #333; padding: .5em 2em;border-radius: 4px">Rafraichir</button>
            </div>
        </template>
    </div>
</template>

<script>
import Loader from "../components/Loader"
import IconEmpty from "../components/IconEmpty"
import { getRequest } from "../configs/functions"
import { getURLApi } from "../configs/functions"

export default {
    components: { Loader, IconEmpty },
    data() {
        return {
            object: {
                isLoading: true
            },
            loading: false
        }
    },
    methods: {
        openInNewTab(href) {
            Object.assign(document.createElement('a'), {
                target: '_blank',
                href: href,
            }).click();
        },
        getSongs() {
            try {

                let id = this.$route.params.transId

                getRequest(`api/album/transaction/${id}`, (state, response) => {
                    if (state) {
                        this.object = {
                            isLoading: false,
                            datas: response,
                            state: true
                        }
                    }else {
                        this.object = {
                            isLoading: false,
                            datas: response,
                            state: false,
                            message: response
                        }
                    }
                })

            } catch (error) {
                console.log(error);
            }
        },
        download(item) {
            try {
                this.openInNewTab(`${getURLApi()}/api/download/free/song/${item.id}`); 
            } catch (error) {
                this.$swal({
                    title: 'Téléchargement',
                    text: error,
                    icon: 'error',
                });
            }
        }
    },
    mounted() {
        this.getSongs()
    }
}
</script>