import axios from "axios";
import Cookies from "js-cookie";
import domtoimage from "dom-to-image";
import html2canvas from "html2canvas";
import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
import { saveAs } from "file-saver";
import InApp from "detect-inapp";
const zip = new JSZip();

/*
    Renvoi l'url de l'api
*/

function getURLApi() {
  return "https://api-mdmusicrdc.herokuapp.com";
}

/*
    Renvoi l'url de l'api
*/

function getURLWeb() {
  return "https://web-networking.herokuapp.com";
}

/**        

 * Verifie si un objet n'est pas vide
 * @param {*} object 
 * @returns 
 */

const notEmpty = (object) => {
  let flag = false;

  for (const value in object) {
    if (object[value] !== "") {
      flag = true;
    } else {
      flag = false;
      break;
    }
  }

  return flag;
};

/**
 * Post les données
 * @param {*} url adresse
 * @param {*} datas données
 * @param {*} callback la reponse
 */

function postRequest(url, datas, callback) {
  if (notEmpty(datas)) {
    var token = getStore() && getStore().token ? getStore().token : null;
    var config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    axios.post(`${getURLApi()}/${url}`, datas, token ? config : null).then(
      (res) => {
        if (res.data.state) {
          callback(true, res.data);
        } else {
          callback(false, res.data.message);
        }
      },
      (err) => {
        callback(
          false,
          "Une erreur technique est survenue, reessayer plus tard" + err
        );
      }
    );
  } else {
    callback(false, "Veuillez renseigner toutes les informations");
  }
}

/**
 * Recuperation des données
 * @param {*} url adresse
 * @param {*} callback la reponse
 */

function getRequest(url, callback) {
  var token = getStore() && getStore().token ? getStore().token : null;
  var config = {
    headers: {
      Authorization: token ? "Bearer " + token : null,
    },
  };

  axios.get(`${getURLApi()}/${url}`, config).then(
    (res) => {
      if (res.data.state) {
        callback(true, res.data.data);
      } else {
        callback(false, res.data.message);
      }
    },
    (err) => {
      callback(
        false,
        "Une erreur technique est survenue, reessayer plus tard " + err
      );
    }
  );
}

/**
 * Pour l'edition d'une requete
 * @param {*} url adresse
 * @param {*} datas données
 * @param {*} callback la reponse
 */

function putRequest(url, datas, callback) {
  // if (notEmpty(datas)) {
  var token = getStore() && getStore().token ? getStore().token : null;
  var config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  axios.patch(`${getURLApi()}/${url}`, datas, token ? config : null).then(
    (res) => {
      if (res.data.state) {
        callback(true, res.data);
      } else {
        callback(false, res.data.message);
      }
    },
    (err) => {
      callback(
        false,
        "Une erreur technique est survenue, reessayer plus tard" + err
      );
    }
  );
  // } else {
  //   callback(false, "Veuillez renseigner toutes les informations");
  // }
}

/**
 * Pour Suppression
 * @param {*} url adresse
 * @param {*} callback la reponse
 */

function deleteRequest(url, callback) {
  var token = getStore() && getStore().token ? getStore().token : null;
  var config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  axios.delete(`${getURLApi()}/${url}`, token ? config : null).then(
    (res) => {
      if (res.data.state) {
        callback(true, res.data);
      } else {
        callback(false, res.data.message);
      }
    },
    (err) => {
      callback(
        false,
        "Une erreur technique est survenue, reessayer plus tard " + err
      );
    }
  );
}

/**
 * Renvoi les informations stockées dans le store
 * @returns
 */
function getStore() {
  var datas = Cookies.get("authNetworking")
    ? JSON.parse(Cookies.get("authNetworking"))
    : null;

  return datas;
}

/**
 * Met à jour les informations du store
 * @param {*} item L'element a modifier
 * @param {*} newDatas Les nouvelles entrées
 */
function updateStore(item, newDatas) {
  var datas = Cookies.get("authNetworking")
    ? JSON.parse(Cookies.get("authNetworking"))
    : null;
  datas[item] = newDatas;

  Cookies.set("authNetworking", JSON.stringify(datas));

  console.log(JSON.parse(Cookies.get("authNetworking")));
}

/**
 * Fonction permettant de deconncter un user
 * @param {*} e
 */
const logout = (e) => {
  e.preventDefault();
  Cookies.remove("authNetworking");
  window.location.reload();
};

/**
 * Permet de faire l'upload des fichiers
 * @param {*} files
 */
function upload(files, element, url, callback) {
  var token = getStore() && getStore().token ? getStore().token : null;
  var config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  var allowedTypes = ["png", "jpg", "jpeg", "svg", "tif"],
    imgType;

  for (var i = 0; i < files.length; i++) {
    imgType = files[i].name.split(".");
    imgType = imgType[imgType.length - 1].toLowerCase();

    if (allowedTypes.indexOf(imgType) !== -1) {
      console.log(files);

      var formData = new FormData(),
        file;
      //reader,
      //sortie = false;

      if (files.length > 0) {
        file = files[0];
        //sortie = true;
      }

      formData.append(element, file, file.name);
      //var url = `${getURLApi()}/api/request/upload`;
      var path = `${getURLApi()}/${url}`;

      axios.post(path, formData, token ? config : null).then((res) => {
        // then print response status
        if (res.data.state) {
          callback(true, res.data);
        } else {
          callback(false, res.data.data);
        }
      });
    } else {
      callback(false, "L'extension choisie est invalide !");
    }
  }
}

/**
 * Converti une date
 * @param {*} date
 * @returns
 */

function convertNormalDate(date) {
  //Conversion du timestamp en milliseconds
  var myDate = new Date(date),
    jour = function () {
      return parseInt(myDate.getDate()) < 10
        ? "0" + myDate.getDate()
        : myDate.getDate();
    },
    mois = function () {
      //return myDate.getMonth() + 1 < 10 ? '0' + (myDate.getMonth() + 1) : myDate.getMonth() + 1
      var month = myDate.getMonth() + 1;

      //La date par rapport à sa nomination
      switch (month) {
        case 1:
          return "janv.";

        case 2:
          return "févr.";

        case 3:
          return "mars";

        case 4:
          return "avril";

        case 5:
          return "mai";

        case 6:
          return "juin";

        case 7:
          return "juill.";

        case 8:
          return "août";

        case 9:
          return "sept.";

        case 10:
          return "oct.";

        case 11:
          return "nov.";

        case 12:
          return "déc.";

        default:
          return null;
      }
    };

  return jour() + " " + mois() + " " + myDate.getFullYear();
}

const convertNormalDateWithMinute = (date) => {
  //Conversion du timestamp en milliseconds
  var myDate = new Date(date),
    jour = function () {
      return parseInt(myDate.getDate()) < 10
        ? "0" + myDate.getDate()
        : myDate.getDate();
    },
    mois = function () {
      //return myDate.getMonth() + 1 < 10 ? '0' + (myDate.getMonth() + 1) : myDate.getMonth() + 1
      var month = myDate.getMonth() + 1;

      //La date par rapport à sa nomination
      switch (month) {
        case 1:
          return "janv.";

        case 2:
          return "févr.";

        case 3:
          return "mars";

        case 4:
          return "avril";

        case 5:
          return "mai";

        case 6:
          return "juin";

        case 7:
          return "juill.";

        case 8:
          return "août";

        case 9:
          return "sept.";

        case 10:
          return "oct.";

        case 11:
          return "nov.";

        case 12:
          return "déc.";

        default:
          return null;
      }
    },
    heure = function () {
      return myDate.getHours() < 10
        ? "0" + myDate.getHours()
        : myDate.getHours();
    },
    minute = function () {
      return myDate.getMinutes() < 10
        ? "0" + myDate.getMinutes()
        : myDate.getMinutes();
    };

  return (
    jour() +
    " " +
    mois() +
    " " +
    myDate.getFullYear() +
    " à " +
    heure() +
    ":" +
    minute()
  );
};

function convertToSlug(Text) {
  return Text.toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "");
}

function stripeCompoment(price) {
  // This is your test publishable API key.
  const stripe = require("stripe")(
    "pk_live_51K0ZAzL9qZGXGV5DdRKAI1xC5AZywn9LCvk6G3c7nKsrswjYi6MSC0aWu1GbehZvPfFlZ7v7s4fTJeFqVdCpYqEh00AAH9qiRz"
  );
  console.log(stripe.elements);
  let elements;

  initialize();
  checkStatus();

  document
    .querySelector("#payment-form")
    .addEventListener("submit", handleSubmit);

  // Fetches a payment intent and captures the client secret
  async function initialize() {
    const response = await fetch(
      "https://api-mdmusicrdc.herokuapp.com/api/stripe/pay",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ amount: price * 100 }),
      }
    );

    const { data } = await response.json();

    const clientSecret = data.client_secret;

    const appearance = {
      theme: "stripe",
    };

    elements = stripe.elements({ appearance, clientSecret });

    const paymentElement = elements.create("payment");
    paymentElement.mount("#payment-element");
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: "http://localhost:4242/checkout.html",
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      showMessage(error.message);
    } else {
      showMessage("An unexpected error occured.");
    }

    setLoading(false);
  }

  // Fetches the payment intent status after payment submission
  async function checkStatus() {
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);

    switch (paymentIntent.status) {
      case "succeeded":
        showMessage("Payment succeeded!");
        break;
      case "processing":
        showMessage("Your payment is processing.");
        break;
      case "requires_payment_method":
        showMessage("Your payment was not successful, please try again.");
        break;
      default:
        showMessage("Something went wrong.");
        break;
    }
  }

  // ------- UI helpers -------

  function showMessage(messageText) {
    const messageContainer = document.querySelector("#payment-message");

    messageContainer.classList.remove("hidden");
    messageContainer.textContent = messageText;

    setTimeout(function () {
      messageContainer.classList.add("hidden");
      messageText.textContent = "";
    }, 4000);
  }

  // Show a spinner on payment submission
  function setLoading(isLoading) {
    if (isLoading) {
      // Disable the button and show a spinner
      document.querySelector("#submit").disabled = true;
      document.querySelector("#spinner").classList.remove("hidden");
      document.querySelector("#button-text").classList.add("hidden");
    } else {
      document.querySelector("#submit").disabled = false;
      document.querySelector("#spinner").classList.add("hidden");
      document.querySelector("#button-text").classList.remove("hidden");
    }
  }
}

function downloadCanvas(id, label) {
  var node = document.getElementById(id);
  domtoimage
    .toPng(node)
    .then(function (dataUrl) {
      var link = document.createElement("a");
      link.download = label + ".jpg";
      link.href = dataUrl;
      link.click();
    })
    .catch(function (error) {
      console.error("oops, something went wrong!", error);
    });
}

function htmlCanvas(id, label) {
  var html = document.getElementById(id);
  html2canvas(html).then(function (canvas) {
    var dataUrl = canvas.toDataURL();
    var link = document.createElement("a");
    link.download = label + ".jpg";
    link.href = dataUrl;
    link.click();
  });
}

function zipFiles() {
  var count = 0;
  var zipFilename = "zipFilename.zip";
  var urls = [
    "https://web.mdmusicrdc.com/files/mp3/5326a59ebd46c5e1e2d3d84909729ccb.mp3",
  ];

  urls.forEach(function (url) {
    var filename = "filename";
    // loading a file and add it in a zip file
    JSZipUtils.getBinaryContent(url, function (err, data) {
      console.log(data);

      if (err) {
        throw err; // or handle the error
      }

      zip.file(filename, data, { binary: true });
      count++;
      if (count == urls.length) {
        zip.generateAsync({ type: "blob" }).then(function (content) {
          saveAs(content, zipFilename);
        });
      }
    });
  });
}

function getNavigator() {
  const inapp = new InApp(navigator.userAgent);
  return inapp.browser;
}

function copy() {
  const area = document.querySelector("#clipboard-area");
  area.select();
  document.execCommand("copy");
}

export {
  htmlCanvas,
  postRequest,
  zipFiles,
  deleteRequest,
  getRequest,
  putRequest,
  getURLApi,
  getStore,
  updateStore,
  copy,
  logout,
  upload,
  getURLWeb,
  stripeCompoment,
  convertNormalDate,
  downloadCanvas,
  html2canvas,
  convertToSlug,
  convertNormalDateWithMinute,
  getNavigator,
};
