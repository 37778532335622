<template>
  <div class="content">
    <div v-if="object.state" class="iphone">
      <div>
        <div style="text-align: center">
          <img
            style="object-fit: cover; border-radius: 5px"
            width="120"
            height="120"
            :src="object.datas.cover"
          />
        </div>
        <div v-if="this.getNavigator() === 'facebook'">
          <p style="text-align: center; margin-top: 2em; margin-bottom: 2em">
            Copiez le lien, ouvrez le dans votre navigateur ( chrome, safari ou
            autres ) pour continuer le processus d’achat.
          </p>
          <input
            id="clipboard-area"
            class="form-control"
            :value="url"
            type="text"
          />

          <button
            style="margin-top: 2em"
            @click="copy"
            class="button button--full"
          >
            Copier le lien
          </button>
        </div>
        <div v-else>
          <form class="form" method="POST">
            <fieldset>
              <legend
                style="text-align: center"
                v-if="$route.params.object == 'songs'"
                class="info"
              >
                Vous vous apprêtez à acheter le titre
                <strong style="color: #fff">{{ object.datas.name }}</strong> de
                l'artiste
                <strong style="color: #fff">{{
                  object.datas.authorName
                }}</strong>
                pour ${{ object.datas.price }}
              </legend>

              <legend
                style="text-align: center"
                v-if="$route.params.object == 'ringtones'"
                class="info"
              >
                Vous vous apprêtez à acheter la sonnerie
                <strong style="color: #fff">{{ object.datas.name }}</strong> de
                l'artiste
                <strong style="color: #fff">{{
                  object.datas.authorName
                }}</strong>
                pour ${{ object.datas.price }}
              </legend>

              <legend
                style="text-align: center"
                v-if="$route.params.object == 'ebooks'"
                class="info"
              >
                Vous vous apprêtez à acheter le livre
                <strong style="color: #fff">{{ object.datas.name }}</strong> de
                l'auteur
                <strong style="color: #fff">{{
                  object.datas.authorName
                }}</strong>
                pour ${{ object.datas.price }}
              </legend>

              <legend
                style="text-align: center"
                v-if="$route.params.object == 'albums'"
                class="info"
              >
                Vous vous apprêtez à acheter l'album
                <strong style="color: #fff">{{ object.datas.name }}</strong> de
                l'artiste
                <strong style="color: #fff">{{
                  object.datas.authorName
                }}</strong>
                pour ${{ object.datas.price }}
              </legend>

              <legend
                style="text-align: center"
                v-if="$route.params.object == 'videos'"
                class="info"
              >
                Vous vous apprêtez à acheter la vidéo
                <strong style="color: #fff">{{ object.datas.name }}</strong> de
                l'artiste
                <strong style="color: #fff">{{
                  object.datas.authorName
                }}</strong>
                pour ${{ object.datas.price }}
              </legend>

              <legend
                style="text-align: center"
                v-if="$route.params.object == 'events'"
                class="info"
              >
                Vous vous apprêtez à acheter le ticket
                <strong v-if="typeTicket === 'standard'" style="color: #fff"
                  >Standard de {{ object.datas.priceStandard }}$</strong
                >
                <strong v-if="typeTicket === 'couple'" style="color: #fff"
                  >Couple de {{ object.datas.priceCouple }}$</strong
                >
                <strong v-if="typeTicket === 'vip'" style="color: #fff"
                  >VIP de {{ object.datas.priceVIP }}$</strong
                >
                pour l'evenement
                <strong style="color: #fff">{{ object.datas.name }}</strong>
              </legend>

              <header class="header">
                <h1>Payer par</h1>
              </header>

              <div class="form__radios">
                <div
                  @click="payement('visa')"
                  class="form__radio"
                  :class="paymentMethod == 'visa' && 'checked'"
                >
                  <label for="visa"
                    ><img style="width: 40%" src="/payments.svg"
                  /></label>
                </div>
              </div>

              <legend class="secondary">Ou utilisez le mobile money</legend>

              <div class="form__radios">
                <div
                  @click="payement('vodacom')"
                  class="form__radio"
                  :class="paymentMethod == 'vodacom' && 'checked'"
                >
                  <label for="vodacom"
                    ><img style="width: 12%" src="/m-pesa.svg" />&nbsp;Vodacom
                    M-Pesa</label
                  >
                </div>

                <div
                  @click="payement('orange')"
                  class="form__radio"
                  :class="paymentMethod == 'orange' && 'checked'"
                >
                  <label for="paypal"
                    ><img style="width: 12%" src="/orage.svg" />&nbsp;Orange
                    Money</label
                  >
                </div>
              </div>
              <template
                v-if="paymentMethod == 'vodacom' || paymentMethod == 'orange'"
              >
                <legend class="input-label">Votre numéro de téléphone</legend>
                <div>
                  <input
                    v-model="numberPhone"
                    :placeholder="
                      paymentMethod === 'vodacom'
                        ? '24381xxxxxxx'
                        : '084xxxxxxx'
                    "
                    class="form-control"
                    name="number-phone"
                    type="text"
                  />
                </div>
              </template>
            </fieldset>

            <div v-if="paymentMethod == 'visa'">
              <button @click="payStripe" class="button button--full">
                Continuer
              </button>
            </div>

            <div v-if="paymentMethod == 'vodacom' || paymentMethod == 'orange'">
              <button
                :disabled="loading"
                @click="pay"
                class="button button--full"
                type="submit"
              >
                {{ this.loading ? "Patientez..." : "Payer" }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <template v-if="object.isLoading">
      <div
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          height: 18em;
        "
        class="content container-fluid"
      >
        <loader />
      </div>
    </template>

    <template v-if="object.state === false">
      <div
        style="
          display: flex;
          margin: auto;
          justify-content: center;
          margin-top: 2em;
        "
      >
        <icon-empty />
      </div>
      <div style="text-align: center; margin-top: 2em">
        {{ object.message }}
      </div>
      <br />
      <div style="text-align: center">
        <button
          @click="refresh"
          style="
            background: #ddd;
            color: #333;
            padding: 0.5em 2em;
            border-radius: 4px;
          "
        >
          Rafraichir
        </button>
      </div>
    </template>
  </div>
</template> 

<script>
import {
  getRequest,
  postRequest,
  getNavigator,
  copy,
} from "../configs/functions";
import Loader from "../components/Loader";
import IconEmpty from "../components/IconEmpty";
// import axios from "axios";

export default {
  name: "Home",
  components: { Loader, IconEmpty },
  data() {
    return {
      paymentMethod: "visa",
      numberPhone: "",
      typeTicket: "",
      loading: false,
      url: "",
      object: {
        isLoading: true,
      },
    };
  },
  methods: {
    payement(method) {
      this.paymentMethod = method;
    },
    getObject() {
      try {
        let item = this.$route.params.object;
        let id = this.$route.params.id;

        this.object = {
          isLoading: true,
        };

        getRequest(`api/web/item/${item}/${id}`, (state, response) => {
          console.log(response);
          if (state) {
            this.object = {
              isLoading: false,
              datas: response,
              state: true,
            };
          } else {
            this.object = {
              isLoading: false,
              datas: null,
              state: false,
              message: response,
            };
          }
        });
      } catch (error) {
        this.object = {
          isLoading: false,
          datas: null,
          state: false,
          message: error,
        };
      }
    },
    pay(e) {
      e.preventDefault();
      try {
        if (this.paymentMethod && this.numberPhone) {
          this.loading = true;

          var { author, price, id } = this.object.datas;

          var ref = this.$route.params.object;
          console.log(price);
          // var title = this.$route.params.title
          var newRef = "";

          switch (ref) {
            case "songs":
              newRef = "Song";
              break;

            case "events":
              newRef = "Event";
              break;

            case "albums":
              newRef = "Album";
              break;

            case "ebooks":
              newRef = "Ebook";
              break;

            case "videos":
              newRef = "Video";
              break;

            case "ringtones":
              newRef = "Ringtone";
              break;

            default:
              break;
          }

          var payload = {
            amount: price,
            phone: this.numberPhone,
            ref: newRef,
            article: id,
            owner: author,
            type: this.typeTicket,
          };

          postRequest(
            `api/${this.paymentMethod}/pay`,
            payload,
            (state, response) => {
              if (state) {
                const ref = this.$route.params.object;
                const title = this.$route.params.title;
                const id = this.$route.params.id;

                if (ref === "events") {
                  window.location.href = `/${ref}/${title}/${id}/${response.data.transId}/qr-code/${this.paymentMethod}`;
                } else {
                  window.location.href = `/${ref}/${title}/${id}/${response.data.transId}/success/${this.paymentMethod}`;
                }
              } else {
                this.$swal({
                  title: "Paiement",
                  text: response,
                  icon: "warning",
                });

                this.loading = false;
              }
            }
          );
        } else {
          this.$swal({
            title: "Paiement",
            text: "Veuillez renseigner le numéro de téléphone ou la méthode de paiement",
            icon: "warning",
          });
        }
      } catch (error) {
        this.$swal({
          title: "Paiement",
          text: error,
          icon: "error",
        });
      }
    },
    payStripe(e) {
      e.preventDefault();

      var ref = this.$route.params.object;

      localStorage.setItem("item", JSON.stringify(this.object.datas));

      switch (ref) {
        case "songs":
          localStorage.setItem("ref", "Song");
          break;

        case "events":
          localStorage.setItem("ref", "Event");
          break;

        case "albums":
          localStorage.setItem("ref", "Album");
          break;

        case "ebooks":
          localStorage.setItem("ref", "Ebook");
          break;

        case "videos":
          localStorage.setItem("ref", "Video");
          break;

        case "ringtones":
          localStorage.setItem("ref", "Ringtone");
          break;

        default:
          break;
      }

      window.location.href = "/stripe";
    },
    getNavigator,
    copy,
    refresh() {
      window.location.reload();
    },
  },
  mounted() {
    this.getObject();
    this.typeTicket = localStorage.getItem("ticket");
    this.url = window.location.href;
  },
};
</script>

<style>
</style>
