import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Process from "../views/Process.vue";
import SuccessStripe from "../views/SuccessStripe.vue";
import About from "../views/About.vue";
import QrCode from "../views/QrCode.vue";
import LandingPage from "../views/LandingPage.vue";
import PassWord from "../views/PassWord.vue";
import Event from "../views/Event.vue";
import EventDetails from "../views/EventDetails.vue";
import Album from "../views/Album.vue";
import DownloadAlbum from "../views/DownloadAlbum.vue";
import PrivacyPolicy from "../views/PrivacyPolicy.vue";

const routes = [
  {
    path: "/download/:transId",
    name: "DownloadAlbum",
    component: DownloadAlbum,
  },
  {
    path: "/password/reset/:id",
    name: "PassWord",
    component: PassWord,
  },
  {
    path: "/:object/:title/:id",
    name: "Home",
    component: Home,
  },
  {
    path: "/:object/:title/:id/:transId/download-page",
    name: "Process",
    component: Process,
  },
  {
    path: "/:object/:title/:id/:transId/success/:payementType",
    name: "SuccessStripe",
    component: SuccessStripe,
  },
  {
    path: "/:object/:title/:id/:transId/get-album",
    name: "Album",
    component: Album,
  },
  {
    path: "/:object/:title/:id/:transId/qr-code/:payementType",
    name: "QrCode",
    component: QrCode,
  },
  {
    path: "/stripe",
    name: "About",
    component: About,
  },
  {
    path: "/event/:id",
    name: "Event",
    component: Event,
  },
  {
    path: "/events/:title/:id/infos",
    name: "EventDetails",
    component: EventDetails,
  },
  {
    path: "/",
    name: "LandingPage",
    component: LandingPage,
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
