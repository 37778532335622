<template class="body">
  <div class="content" style="display: flex;justify-content: center;">
      <!-- Display a payment form -->
      <form class="form-stripe" style="padding: 40px;" id="payment-form">
        <div id="payment-element">
          <!--Stripe.js injects the Payment Element-->
          <div style="display: flex;justify-content: center;align-items: center;height: 18em;" class="content container-fluid">
            <loader />
          </div>
        </div>
        <button class="button" id="submit">
          <div class="spinner hidden" id="spinner"></div>
          <span id="button-text">Payer maintenant 
            <template v-if="ref === 'Event'">
              <span v-if="ticket === 'standard'">({{object && object.priceStandard}}$)</span>
              <span v-if="ticket === 'couple'">({{object && object.priceCouple}}$)</span>
              <span v-if="ticket === 'vip'">({{object && object.priceVIP}}$)</span>
            </template>
            <template v-else>
              ({{object && object.price}}$)
            </template>
          </span>
        </button>
        <div id="payment-message" class="hidden"></div>

        <legend style="text-align: center" v-if="ref == 'Event'" class="info">
            Vous vous apprêtez à acheter le ticket 
            <strong v-if="ticket === 'standard'" style="color:#fff">Standard de {{object && object.priceStandard}}$</strong> 
            <strong v-if="ticket === 'couple'" style="color:#fff">Couple de {{object && object.priceCouple}}$</strong> 
            <strong v-if="ticket === 'vip'" style="color:#fff">VIP de {{object && object.priceVIP}}$</strong> 
            pour l'evenement
            <strong style="color:#fff">{{ object && object.name }}</strong>
        </legend>

        <legend v-else-if="ref == 'Album'" style="text-align: center" class="info">
          Vous vous apprêtez à acheter l'album <strong>{{ object && object.name }}</strong> de l'artiste <strong>{{ object && object.authorName }}</strong> pour ${{ object && object.price }}
        </legend>

        <legend style="text-align: center" v-else class="info">
          Vous vous apprêtez à acheter l'article <strong>{{ object && object.name }}</strong> de l'artiste <strong>{{ object && object.authorName }}</strong> pour ${{ object && object.price }}
        </legend>


      </form>
      
  </div>
</template>

<script>

import Loader from "../components/Loader"

export default {
  components: { Loader },
  data(){
    return {
      object: null,
      ref: null,
      ticket: null
    }
  },
  mounted(){
    this.object = JSON.parse(localStorage.getItem("item"))
    this.ref = localStorage.getItem("ref")
    this.ticket = localStorage.getItem("ticket")
  }
}; 


</script>

<style>

.label{
  color: #fff !important;
}

.form-stripe {
  width: 30vw;
  min-width: 500px;
  align-self: center;
  border-radius: 0px;
}

.hidden {
  display: none;
}

#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}

/* Buttons and links */
.button {
  background: #5469d4;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}
button:hover {
  filter: contrast(115%);
}
button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}
.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.spinner:before,
.spinner:after {
  position: absolute;
  content: "";
}
.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}
.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  .form-stripe {
    width: 100%;
    min-width: initial;
  }
}
</style>