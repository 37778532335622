<template>
  <div class="content" style="display: flex; justify-content: center">
    <div
      v-if="loading === false && message.text === null"
      style="padding: 0px"
      class="form-stripe iphone"
    >
      <div id="invoice">
        <div style="text-align: center">
          <img
            height="100"
            width="100"
            src="/img/app_logo.png"
            alt=""
            srcset=""
          />
        </div>
        <br />

        <h1 style="text-align: center; color: #fff">
          Félicitations pour votre achat chez MD Music
        </h1>

        <ul class="event-list-infos">
          <li class="flex space-x-auto bottom-1 baseline">
            <div>
              <p class="white event-type-pricing">Id transaction</p>
            </div>
            <div>
              <p class="pink">
                {{
                  invoice && invoice.transaction && invoice.transaction.transId
                }}
              </p>
            </div>
          </li>
          <li class="flex space-x-auto bottom-1 baseline">
            <div>
              <p class="white event-type-pricing">Article</p>
            </div>
            <div>
              <p class="pink">
                {{ invoice && invoice.article && invoice.article.name }}
              </p>
            </div>
          </li>
          <li class="flex space-x-auto bottom-1 baseline">
            <div>
              <p class="white event-type-pricing">Prix</p>
            </div>
            <div>
              <p class="pink">
                {{ invoice && invoice.article && invoice.article.price }} $
              </p>
            </div>
          </li>
          <li class="flex space-x-auto bottom-1 baseline">
            <div>
              <p class="white event-type-pricing">Auteur</p>
            </div>
            <div>
              <p class="pink">
                {{ invoice && invoice.article && invoice.article.authorName }}
              </p>
            </div>
          </li>
          <li class="flex space-x-auto bottom-1 baseline">
            <div>
              <p class="white event-type-pricing">Date et heure</p>
            </div>
            <div>
              <p class="pink">
                {{
                  invoice &&
                  invoice.transaction &&
                  convertNormalDateWithMinute(invoice.transaction.createdAt)
                }}
              </p>
            </div>
          </li>
        </ul>
      </div>

      <div style="padding: 2em">
        <small v-if="object && object !== 'Album'"
          >Veuillez telecharger votre votre article et votre preuve de paiement
          en cliquant sur le bouton ci-dessous.</small
        >
        <br />
        <br />

        <button
          style="background: #8d96c7"
          @click="downloadAlbum"
          class="button button--full"
        >
          Télécharger la preuve de paiement</button
        ><br />

        <div v-if="object && object === 'Album' && songs">
          <button @click="redirectAlbum" class="button button--full">
            Télécharger l'album
          </button>
        </div>

        <button
          v-else-if="object && object !== 'Album'"
          @click="download"
          class="button button--full"
          :disabled="loadingDownload"
        >
          <span v-if="loadingDownload">Téléchargement encours</span>
          <span v-if="!loadingDownload">Télécharger l'article</span>
        </button>
      </div>
    </div>
    <template v-if="loading">
      <div style="display: block">
        <div style="display: flex; justify-content: center">
          <loader />
        </div>
        <div v-if="loadingRefresh === false" style="text-align: center">
          <h4>
            Si après paiement la page du téléchargement ne s'affiche pas,
            veuillez cliquer sur le bouton ci-dessous
          </h4>
          <br />
          <br />
          <button
            @click="refreshDatas"
            style="
              background: #ddd;
              color: #333;
              padding: 0.5em 2em;
              border-radius: 4px;
            "
          >
            Cliquer ici
          </button>
        </div>
      </div>
    </template>
    <template v-if="message.text">
      <div style="display: block">
        <div
          style="
            display: flex;
            margin: auto;
            justify-content: center;
            margin-top: 2em;
          "
        >
          <icon-empty />
        </div>
        <div style="text-align: center; margin-top: 2em">
          {{ message.text }}
        </div>
        <br />
        <div style="text-align: center">
          <button
            @click="refresh"
            style="
              background: #ddd;
              color: #333;
              padding: 0.5em 2em;
              border-radius: 4px;
            "
          >
            Recharger la page
          </button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { getURLApi, convertNormalDateWithMinute } from "../configs/functions";
import { getRequest, htmlCanvas } from "../configs/functions";
import Loader from "../components/Loader";
import IconEmpty from "../components/IconEmpty";
import io from "socket.io-client";

export default {
  components: { Loader, IconEmpty },
  data() {
    return {
      loading: false,
      loadingDownload: false,
      loadingRefresh: false,
      object: "",
      invoice: "",
      songs: null,
      message: {
        type: null,
        text: null,
      },
    };
  },
  // components: {Invoive},
  methods: {
    openInNewTab(href) {
      Object.assign(document.createElement("a"), {
        target: "_blank",
        href: href,
      }).click();
    },
    download() {
      try {
        let transId = this.$route.params.transId;
        this.openInNewTab(`${getURLApi()}/api/download/file/${transId}`);
      } catch (error) {
        this.$swal({
          title: "Téléchargement",
          text: error,
          icon: "error",
        });
      }
      // this.loadingDownload = true;
      // try {
      //   axios
      //     .get(this.invoice.article.file, {
      //       responseType: "blob",
      //     })
      //     .then((res) => {
      //       console.log(res);
      //       this.loadingDownload = false;
      //       fileDownload(res.data, this.invoice.article.name + ".mp3");
      //     });
      // } catch (error) {
      //   alert(error.message, "Une erreur est survenue");
      //   this.loadingDownload = false;
      // }
    },
    downloadAlbum() {
      let transId = this.$route.params.transId;
      // downloadCanvas('invoice', transId)
      htmlCanvas("invoice", transId);
    },
    redirectAlbum(e) {
      e.preventDefault();
      let transId = this.$route.params.transId;
      this.openInNewTab(`/download/${transId}`);
    },
    getSongs() {
      try {
        let id = this.$route.params.transId;

        getRequest(`api/album/transaction/${id}`, (state, response) => {
          if (state) {
            this.songs = response;
          }
        });
      } catch (error) {
        console.log(error);
      }
    },
    socketVodacom(transId) {
      this.loading = true;
      // const ref = this.$route.params.object
      // const title = this.$route.params.title
      // const id = this.$route.params.id

      const socket = io("https://api-mdmusicrdc.herokuapp.com");

      socket.emit("join", transId);

      socket.on("callback", (data) => {
        console.log(data);
        if (data.state) {
          getRequest(
            `api/transaction/details/${transId}`,
            (state, response) => {
              this.loading = false;
              if (state) {
                console.log(response);
                this.invoice = response;
              } else {
                this.message = {
                  text: response.message,
                  type: "error",
                };
              }
            }
          );
        } else {
          this.loading = false;
          this.message = {
            text: data.message,
            type: "error",
          };
        }
      });
    },
    refreshDatas() {
      var transId = this.$route.params.transId;
      this.loading = true;
      this.loadingRefresh = true;

      getRequest(`api/transaction/details/${transId}`, (state, response) => {
        this.loading = false;
        this.loadingRefresh = false;

        console.log(response);

        if (state) {
          this.invoice = response;
        } else {
          this.message = {
            text: response,
            type: "error",
          };
        }
      });
    },
    refresh() {
      window.location.reload();
    },
    convertNormalDateWithMinute,
  },
  mounted() {
    this.object = this.$route.params.object;

    this.getSongs();

    var ref = this.$route.params.payementType;
    var transId = this.$route.params.transId;
    
    if (ref === "stripe") {
      this.invoice = JSON.parse(localStorage.getItem("md-success-ok"));
      console.log(JSON.parse(localStorage.getItem("md-success-ok")));
    } else {
      this.socketVodacom(transId);
    }
  },
};
</script>

<style>
#invoice {
  background: #1a1d22;
  padding: 2em;
}

.footer-invoice {
  background: #fbbb0e;
}
</style>
