<template>
    <div class="landing-page landing-page1">
        <nav class="navbar navbar-transparent navbar-top" role="navigation">
            <div class="container">
                <!-- Brand and toggle get grouped for better mobile display -->
                <div class="navbar-header">
                    
                    <a href="/">
                        <div class="logo-container">
                            <div class="logo">
                                <img src="/img/logomd.png" alt="MD Music Logo">
                            </div>
                           
                        </div>
                    </a>
                </div>
                <!-- Collect the nav links, forms, and other content for toggling -->
               
                <!-- /.navbar-collapse -->
            </div>
        </nav>

        <div class="wrapper">
            <div class="parallax filter-gradient yellow" data-color="yellow">
                <div class="parallax-background">
                    <img class="parallax-background-image" src="/img/bg2.jpg">
                </div>
                <div class= "container">
                    <div class="row">
                        <div class="col-md-7">
                            <div class="description">
                                <h2><strong>Découvrez  la nouvelle version de MD MUSIC RDC,  déjà disponible</strong></h2>
                                <br>
                                <h5>Nous sommes exclusivement disponbile via notre application mobile sur PlayStore et Appstore.</h5>
                            
                                <h5>Rejoignez-nous !</h5>
                            </div>
                            <div class="buttons">
                                <a target="_blank" href="https://apps.apple.com/be/app/md-music/id1581666785?l=fr"><button class="btn btn-simple btn-neutral">
                                <i class="fa fa-apple"></i> Appstore 
                                </button></a>
                                <a target="_blank" href="https://play.google.com/store/apps/details?id=com.mdmusicrdc.mdmusic"><button class="btn btn-fill btn-neutral">
                                 <i class="fa fa-android"></i> Googleplay
                                </button></a>
                               
                            </div>
                        </div>
                        <div class="col-md-5  hidden-xs">
                            <div class="parallax-image">
                                <img class="phone" src="/img/phone2.png"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section section-gray section-clients">
                <div class="container text-center">
                    <h4 class="header-text">Moyen de paiement disponible</h4>
                
                    <div class="logos">
                        <ul class="list-unstyled">
                            <li ><img src="/img/visa.png"/></li>
                            <li ><img src="/img/orangemoney.jpeg"/></li>
                            <li ><img src="/img/mpesa2.png"/></li>

                        </ul>
                    </div>
                </div>
            </div>
            <footer class="footer">
                <div class="container">
                    
                    <div class="copyright">
                        &copy; 2021 <a href="https://www.mdmusicrdc.com/">MD Music RDC</a><br>
                        Whatsapp : +243 891 477 772 <br>
                        Email : contact@mdmusicrdc.com

                    </div>
                </div>
            </footer>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>



