<template>
  <div class="privacy">
    <div class="header-privacy">
      <a href="/">
        <div class="logo-container">
          <div class="logo">
            <img src="/img/logomd.png" alt="MD Music Logo" />
          </div>
        </div>
      </a>
      <h5>POLITIQUE DE CONFIDENTIALITÉ MD MUSIC RDC</h5>
      <h6>MD MUSIC SARLU</h6>
      <p>Type de site : e-commerce et application mobile</p>
    </div>
    <div class="content-privacy">
      <p>Le but de cette politique de confidentialité</p>
      <p>
        Le but de cette politique de confidentialité est d'informer les
        utilisateurs de notre site des données personnelles que nous
        recucillerons ainsi
      </p>
      <p>Que les informations suivantes, le cas échéant :</p>

      <ul style="list-style-type: none">
        <li>A. Les données personnelles que nous recueillerons</li>
        <li>B. L'utilisation des données recueillies</li>
        <li>B. Qui a accès aux données recueillies</li>
        <li>B. Les droits des utilisateurs du site</li>
      </ul>

      <p>
        Cette politique de confidentialité fonctionne parallèlement aux
        conditions générales d'utilisation de notre site.
      </p>
      <hr />
      <h6>Lois applicables</h6>
      <p>
        Conformément au Règlement général sur la protection des données (RGPD),
        cette politique de confidentialité est conforme aux règlements suivants.
      </p>

      <p>Les données à caractère personnel doivent être:</p>

      <ul style="list-style-type: none">
        <li>
          A. Traitées de manière licite, loyale et transparente au regard de la
          personne concernée (licéité, loyauté, transparence) ;
        </li>
        <li>
          B. Collectées pour des finalités déterminées, explicites et légitimes,
          et ne pas être traitées ultérieurement d'une manière incompatible avec
          Ces finalités; le traitement ultérieur à des fins archivistiques dans
          l'intérêt public, à des fins de recherche scientifique ou historique
          ou à des fins statistiques n'est pas considéré, conformément à
          l'article 89, paragraphe1, comme incompatible avec les finalités
          initiales (limitation des finalités)
        </li>
        <li>
          C. Adéquates, pertinentes et limitées à ce qui est nécessaire au
          regard des finalités pour lesquelles elles sont traitées (minimisation
          des données):
        </li>
        <li>
          D. Exactes et, si nécessaire, tenues à jour; toutes les mesures
          raisonnables doivent être prises pour que les données à caractère
          personnel Qui sont inexactes, eu égard aux finalités pour lesquelles
          elles sont traitées, soient effacées ou rectifiées sans tarder
          (exactitude);
        </li>
        <li>
          E. conservées sous une forme permettant l'identification des personnes
          concernées pendant une durée n'excédant pas celle nécessaire au Regard
          des finalités pour lesquelles elles sont traitées; les données à
          caractère personnel peuvent être conservées pour des durées plus
          Longues dans la mesure où elles seront traitées exclusivement à des
          fins archivistiques dans l'intérêt public, à des fins de recherche
          Scientifique ou historique ou à des fins statistiques conformément à
          l'article 89, paragraphe1, pour autant que soient mises en œuvre Les
          mesures techniques et organisationnelles appropriées requises par le
          règlement afin de garantir les droits et libertés de la personne
          Concemée (limitation de la conservation)
        </li>
        <li>
          F. Traitées de façon à garantir une sécurité appropriée des données à
          caractère personnel, y compris la protection contre le traitement non
          Autorisé ou illicite et contre la perte, la destruction ou les dégâts
          d'origine accidentelle, à l'aide de mesures techniques ou
          Organisationnelles appropriées (intégrité et confidentialité).
        </li>
      </ul>

      <p>
        Le traitement n'est licite que si, et dans la mesure où, au moins une
        des conditions suivantes est remplie :
      </p>

      <ul style="list-style-type: none">
        <li>
          A. la personne concernée a consenti au traitement de ses données à
          caractère personnel pour une ou plusieurs finalités spécifiques ;
        </li>
        <li>
          B. le traitement est nécessaire à l'exécution d'un contrat auquel la
          personne concernée est partie ou à l'exécution de mesures
          Précontractuelles prises à la demande de celle-ci :
        </li>
        <li>
          C. le traitement est nécessaire au respect d'une obligation légale à
          laquelle le responsable du traitement est soumis ;
        </li>
        <li>
          D. le traitement est nécessaire à la sauvegarde des intérêts vitaux de
          la personne concemée ou d'une autre personne physique :
        </li>

        <li>
          E. le traitement est nécessaire à l'exécution d'une mission d'intérêt
          public ou relevant de l'exercice de l'autorité publique dont est
          investi le responsable du traitement
        </li>

        <li>
          F. le traitement est nécessaire aux fins des intérêts légitimes
          poursuivis par le responsable du traitement ou par un tiers, à moins
          que ne prévalent les intérêts ou les libertés et droits fondamentaux
          de la personne concernée qui exigent une protection des données à
          Caractère personnel, notamment lorsque la personne concernée est un
          enfant.
        </li>
      </ul>

      <p>
        Pour les résidents de l'État de Californie, cette politique de
        confidentialité vise à se conformer à la California Consumer Privacy Act
        (CCP1). S'il y a des incohérences entre ce document et la CCP1, la
        législation de l'État s'appliquera. Si nous constatons des incohérences,
        Nous modifierons notre politique pour nous conformer à la loi
        pertinente.
      </p>
      <hr />

      <h6>Consentement</h6>

      <p>
        Les utilisateurs conviennent qu'en utilisant notre site et application
        mobile, ils consentent à :
      </p>

      <ul>
        <li>
          A. les conditions énoncées dans la présente politique de
          confidentialité et
        </li>
        <li>
          B. la collecte, l'utilisation et la conservation des données énumérées
          dans la présente politique
        </li>
      </ul>
      <hr />
      <h6>Données personnelles que nous collectons</h6>
      <hr />
      <h6>Données collectées automatiquement</h6>

      <p>
        Lorsque vous visitez et utilisez notre site, nous pouvons
        automatiquement recueillir et conserver les renseignements suivants :
      </p>

      <ul style="list-style-type: none">
        <li>A. Adresse IP</li>
        <li>B. Lieu</li>
        <li>C. Numéro de téléphone</li>
      </ul>

      <hr />

      <h6>Données recueillies de façon non automatique</h6>
      <p>
        Nous pouvons également collecter les données suivantes lorsque vous
        effectuez certaines fonctions sur notre site :
      </p>

      <ul style="list-style-type: none">
        <li>A. Email</li>
        <li>B. Numéro de téléphone</li>
      </ul>

      <hr />

      <h6>Données recueillies de façon non automatique</h6>
      <p>
        Nous pouvons également collecter les données suivantes lorsque vous
        eftectuez certaines fonctions sur notre site :
      </p>

      <ul style="list-style-type: none">
        <li>A. Email</li>
        <li>B. Numéro de téléphone</li>
        <li>C. Information de paiement</li>
      </ul>

      <p>
        Ces données peuvent être recueillies au moyen des méthodes suivantes :
        Enregistrement d'un compte et lors du paiement Veuillez noter que nous
        ne collectons que les données qui nous aident à atteindre l'objectif
        énoncé dans cette politique de confidentialité. Nous ne recueillerons
        pas de données supplémentaires sans vous en informer au préalable.
      </p>

      <hr />

      <h6>Comment nous utilisons les données personnelles</h6>
      <p>
        Les données personnelles recueillies sur notre site seront utilisées
        uniquement aux Fins précisées dans la présente politique ou indiquées
        sur les pages pertinentes de notre site.
      </p>
      <p>
        Nous n'utiliserons pas vos données au-delà de ce que nous divulguerons.
      </p>

      <p>
        Les données que nous recueillons automatiquement sont utilisées aux fins
        suivantes :
      </p>

      <ul style="list-style-type: none">
        <li>A. Pour nos statistiques et pour améliorer notre service</li>
      </ul>
      <hr />

      <h6>Avec qui nous partageons les données personnelles</h6>
      <hr />
      <h6>Employés</h6>
      <p>
        Nous pouvons divulguer à tout membre de notre organisation les données
        utilisateur dont il a raisonnablement besoin pour réaliser les objectifs
        énoncés dans la présente politique.
      </p>
      <hr />

      <h6>Tierces parties</h6>
      <p>
        Les tiers ne seront pas en mesure d'accéder aux données des utilisateurs
        au-delà de ce qui est raisonnablement nécessaire pour atteindre
        l'objectif donné.
      </p>
      <hr />

      <h6>Autres divulgations</h6>
      <p>
        Nous nous engageons à ne pas vendre ou partager vos données avec des
        tiers, sauf dans les cas suivants :
      </p>

      <ul style="list-style-type: none">
        <li>A. si la loi l'exige</li>
        <li>B. si elle est requise pour toute procédure judiciaire</li>
        <li>C. pour prouver ou protéger nos droits legaux</li>
        <li>
          D. à des acheteurs ou des acheteurs potentiels de cette société dans
          le cas où nous cherchons à la vendre la société
        </li>
      </ul>

      <p>
        Si vous suivez des hyperliens de notre site vers un autre site, veuillez
        noter que nous ne sommes pas responsables et n'avons pas de contrôle sur
        leurs politiques et pratiques de confidentialité.
      </p>

      <hr />

      <h6>Combien de temps nous stockons les données personnelles</h6>
      <p>
        Les données des utilisateurs seront conservées pour une durée de : 3
        mois Nous veillerons à ce que les utilisateurs soient avisés si leurs
        données sont conservées plus longtemps que cette durée.
      </p>

      <hr />

      <h6>Comment nous protégeons vos données personnelles</h6>
      <p>
        Afin d'assurer la protection de votre sécurité, nous utilisons le
        protocole de sécurité de la couche transport pour transmettre des
        renseignements personnels dans notre système.
      </p>
      <p>
        Toutes les données stockées dans notre système sont bien sécurisées et
        ne sont accessibles qu'à nos employés. Nos employés sont liés par des
        accords de confidentialité stricts et une violation de cet accord
        entraînerait le licenciement de l'employé.
      </p>
      <p>
        Alors que nous prenons toutes les précautions raisonnables pour nous
        assurer que nos données d'utilisateur sont sécurisées et que les
        utilisateurs sont protégés, il reste toujours du risque de préjudice.
      </p>
      <p>
        L'Internet en sa totalité peut être, parfois, peu sûr et donc nous
        sommes incapables de garantir la sécurité des données des utilisateurs
        au-delà de ce qui est raisonnablement pratique.
      </p>

      <hr />
      <h6>Mineurs</h6>
      <p>
        Le RGPD précise que les personnes de moins de 15 ans sont considérées
        comme des mineurs aux fins de la collecte de données.
      </p>

      <p>
        Les mineurs doivent avoir le consentement d'un représentant légal pour
        que leurs données soient recueillies, traitées et utilisées.
      </p>

      <p>
        Nous n'avons pas l'intention de recueillir ou d'utiliser des données
        auprès d'utilisateurs mineurs. Si nous découvrons que nous avons
        recueilli des données d'un mineur, ces données seront supprimées
        immédiatement.
      </p>

      <hr />
      <h6>Vos droits en tant qu'utilisateur</h6>
      <p>
        En vertu du RGPD, les utilisateurs ont les droits suivants en tant que
        personnes concernées :
      </p>

      <ul style="list-style-type: none">
        <li>A. droit d'accès</li>
        <li>B. droit de rectification</li>
        <li>C. droit à l'effacement</li>
        <li>D. droit de restreindre le traitement</li>
        <li>E. droit à la portabilité des données</li>
        <li>F. droit d'objection</li>
      </ul>

      <p>
        Vous trouverez de plus amples informations sur ces droits au chapitre 3
        (art 12-23) du RGPD Comment modifier. Supprimer ou contester les donnees
        Tecuee
      </p>

      <hr />
      <h6>Comment modifier, supprimer ou contester les données recueillies</h6>
      <p>
        Si vous souhaitez que vos renseignements soient supprimés ou modifiés
        d'une façon ou d'une autre, veuillez communiquer avec notre agent de
        protection de la vie privée ici :
      </p>
      <p>Mr Peguy KONGO</p>
      <p>26, av de la paix gombe/kinshasa</p>
      <p>Contact@mdmusicrdc.com</p>
      <p>+243 89 14 77772</p>

      <hr />
      <h6>Politique sur les cookies</h6>
      <p>
        Un cookie est un petit fichier, stocké sur le disque dur d'un
        utilisateur par le site Web. Son but est de recueillir des données
        relatives aux habitudes de navigation de l'utilisateur.
      </p>

      <hr />
      <h6>Cookies fonctionnels</h6>
      <p>
        Nous les utilisons pour mémoriser toutes les sélections que vous faites
        sur notre site afin qu'elles soient sauvegardées pour vos prochaines
        visites
      </p>

      <p>
        Vous pouvez choisir d'être averti chaque fois qu'un cookie est transmis.
        Vous pouvez également choisir de désactiver les cookies entièrement dans
        votre navigateur Internet, mais cela peut diminuer la qualité de votre
        expérience d'utilisation.
      </p>

      <hr />
      <h6>Cookies tiers</h6>
      <p>
        Nous pouvons utiliser des cookies tiers sur notre site pour atteindre
        les objectifs suivants :
      </p>

      <p>A. Pour adapter les besoins aux choix des utilisateurs</p>
      <hr />
      <h6>Modifications</h6>
      <p>
        Cette politique de confidentialité peut être modifiée à l'occasion afin
        de maintenir la conformité avec la loi et de tenir compte de tout
        changement à notre processus de collecte de données.
      </p>

      <p>
        Nous recommandons à nos utilisateurs de vérifier notre politique de
        temps à autre pour s'assurer qu'ils soient informés de toute mise à
        jour.
      </p>

      <p>
        Au besoin, nous pouvons informer les utilisateurs par courriel des
        changements apportés à cette politique.
      </p>

      <hr />
      <h6>Contact</h6>
      <p>
        Si vous avez des questions à nous poser, n'hésitez pas à communiquer
        avec nous en utilisant ce qui suit
      </p>

      <p>TEL : +243 8 91 47 77 72</p>
      <p>Mail : Contact@mdmusicrdc.com</p>
      <p>16, av du président JF kennedy ngaliema/ kinshasa</p>
      <p>Date d'entrée en vigueur : le 5 décembre 2021</p>
    </div>
  </div>
</template>

<style>
.privacy {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding: 4em;
  color: #333 !important;
  width: 80%;
  margin: auto;
  text-align: justify;
}

.privacy h5,
.privacy .h5 {
  font-size: 25px;
  margin-bottom: 15px;
  color: #333;
}

.header-privacy {
  text-align: center;
  margin-bottom: 4em;
}

.header-privacy {
  font-size: 20px;
}
</style>